import React, { useState, useEffect, useMemo, FC } from 'react';
import AppLayout from 'layouts/app-layout';
import {
  DocumentData,
  fileInformationResponseToDocumentData,
  normalizeVerificationDateConfigs,
  validateNoDuplicatedDocumentData,
  validateRequiredDocumentData,
  validateVerificationDateDocumentData,
} from 'helpers/documentHelper';
import { Wrapper, FormHeader } from 'form/form.styles';
import {
  Abbreviation,
  Accordion,
  Button,
  FormControl,
  FieldSelect,
  FieldRadio,
  FieldCheckbox,
  Loading,
  Link as StyledLink,
  PageTitle,
} from 'components/atoms';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';

import {
  DueDiligence,
  DueDiligenceForm,
  DueDiligenceType,
  NoteContainer,
  AcknowledgeWrapper,
  FieldRadioContainer,
  SideModulesWrapper,
  ResponsiveContainer,
  LoadingMessage,
  GlobalSearch,
  ComboBoxContainer,
} from './../InitiateDueDiligence.styles';
import { useForm, useWatch, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { GatewayPermissions } from 'auth/constants/permissions';
import getSymbolFromCurrency from 'currency-symbol-map';

import RelatedEntitiesForm from 'form/related-entities/RelatedEntitiesForm';
import { Panel } from 'components/molecules';
import ThirdPartyOverviewSideModule from 'components/organisms/third-party-overview-side-module/ThirdPartyOverviewSideModule';
import { OtherInformation } from 'components/organisms';
import MultipleDocumentUploadWrapper from 'components/organisms/multiple-document-upload-wrapper';
import { ThirdPartyType } from 'types/thirdPartyType';
import RelatedEntitiesSideModule from '../side-modules/RelatedEntitiesSideModule';
import { useFetch } from 'hooks';
import {
  Section,
  ThirdPartyInfo,
} from 'components/organisms/due-diligence-confirmation-modal/types';
import PotentialDuplicateModal from 'components/organisms/potential-duplicate-modal';
import {
  DuplicatedScreening,
  PotentialDuplicateModalTypes,
} from 'components/organisms/potential-duplicate-modal/types';
import {
  CheckDuplicateResponse,
  DocumentCategory,
  DocumentDataForMDDRequest,
  DraftData,
  DueDiligenceTypesResponse,
  EntitiesRetrievedFile,
  ReportTemplate,
  SelectedRelatedEntity,
} from '../types';
import { ThirdParty } from 'components/organisms/third-party-overview-side-module/types';

interface InititateDueDiligenceFormProps {
  loading: boolean;
  businessCategoryData: DocumentCategory[];
  individualCategoryData: DocumentCategory[];
  ddTypesData: DueDiligenceTypesResponse;
  thirdPartyData: ThirdParty;
  draftData: DraftData;
  optionalAddonsData: any;
  onScreeningSubmit: (data: any) => void;
  onDueDiligenceSubmit: (data: any) => void;
  onSaveDraft: (data: any) => void;
}

interface InititateDueDiligenceFormFields {
  relatedEntities: number[];
  documentsData: any;
  dueDiligenceType: string;
  screeningType: string;
  screeningSettings: string;
  monitoring: string;
  monitoringSettings: string;
  monitoringScreeningSettings: string;
  comments: string;
  customField1: string;
  customField2: string;
  customField3: string;
  reportCustomField1: string;
  reportCustomField2: string;
  reportCustomField3: string;
  reportTemplateId?: number;
  acknowledge: boolean;
  addOns: any[];
  expedite: boolean;
  hasAcknowledgedMessage: boolean;
  isGlobalSearch: boolean;
}

const InitiateDueDiligenceForm: FC<InititateDueDiligenceFormProps> = ({
  loading,
  businessCategoryData,
  individualCategoryData,
  ddTypesData,
  thirdPartyData,
  draftData,
  optionalAddonsData,
  onScreeningSubmit: onExternalScreeningSubmit,
  onDueDiligenceSubmit: onExternalDueDiligenceSubmit,
  onSaveDraft,
}) => {
  //#region dependencies

  //#region props
  const {
    id,
    name: thirdPartyName,
    type: thirdPartyType,
    internalId: thirdPartyInternalId,
  } = thirdPartyData;

  const categories = [...individualCategoryData, ...businessCategoryData];

  //#endregion props

  //#region context

  const { hasPermission, accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { msalSubscriptionKey, vantageWebApi } = useConfig();

  const history = useHistory();

  const { search } = useLocation();

  //#endregion context

  //#region API calls
  const {
    data: relatedEntitiesRawData,
    trigger: fetchRelatedEntitiesLazy,
    loading: relatedEntitiesRawDataLoading,
  } = useFetch(`${vantageWebApi}/thirdParty/${id}/relatedentities`, {
    method: 'GET',
    lazy: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
    },
  });

  //#endregion API calls

  //#region use form
  const schema = yup.object().shape({
    currentStep: yup.number(),
    relatedEntities: yup.array(),
    screeningSettings: yup
      .mixed()
      .test('screening-setting', 'Please select one!', (value) => {
        if (dueDiligenceType === 'Due Diligence') {
          return true;
        }
        return value !== 'DEFAULT';
      }),
    documentsData: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(),
          size: yup.number().required(),
          type: yup.string().required(),
          category: yup.string().required(),
          categoryId: yup.number().required(),
          entityId: yup.number().required(),
          thirdPartyId: yup.number().nullable(),
          verificationDate: yup.string().nullable().optional(),
          // Backend
          id: yup.string().required(),
          fileDownloadName: yup.string().required(),
          uploadedBy: yup.string().required(),
          uploadedDate: yup.string().required(),
          isOptionalDocument: yup.boolean(),
          isRelatedEntityDocument: yup.boolean(),
        })
      )
      .test((documents: DocumentData[], context) => {
        if (dueDiligenceType !== 'Due Diligence') {
          return true;
        }
        return validateRequiredDocumentData(
          'Please attach all mandatory documents for the Primary subject as well as any Related Entities included in the order',
          documents,
          selectedReport?.documentConfig,
          thirdPartyData.type as ThirdPartyType,
          selectedRelatedEntities,
          context.createError
        );
      })
      .test((documents: DocumentData[], context) => {
        if (dueDiligenceType !== 'Due Diligence') {
          return true;
        }
        return validateNoDuplicatedDocumentData(
          'You have two or more files uploaded with the same name, please remove duplication',
          documents,
          context.createError
        );
      })
      .test((documents: DocumentData[], context) => {
        if (dueDiligenceType !== 'Due Diligence') {
          return true;
        }
        return validateVerificationDateDocumentData(
          'You have one or more files uploaded with a invalid date, please update them',
          documents,
          selectedReport?.documentConfig,
          context.createError
        );
      }),
    monitoring: yup
      .mixed()
      .test('screening-setting', 'Please select one!', (value) => {
        if (
          (screeningType === 'Self Service' ||
            screeningType === 'Managed Service') &&
          isSelfServiceMonitoringEnabled
        ) {
          return value !== 'DEFAULT';
        }

        return true;
      }),
    comments: yup.string().max(2000, 'Maximum 2000 characters'),
    monitoringScreeningSettings: yup
      .mixed()
      .test('screening-setting', 'Please select one!', function (value) {
        if (
          this.parent.monitoring === 'enabled' &&
          (screeningType === 'Self Service' ||
            screeningType === 'Managed Service')
        ) {
          return value !== 'DEFAULT';
        } else {
          return true;
        }
      }),
    customField1: yup
      .string()
      .max(80, 'Maximum 80 characters')
      .test('is-required', 'This is a required field', (value) => {
        if (dueDiligenceType === 'Screening') {
          return true;
        }
        if (
          dueDiligenceLevel?.customFieldsConfig?.customField1?.isMandatory &&
          !value
        ) {
          return false;
        }
        return true;
      }),
    customField2: yup
      .string()
      .max(80, 'Maximum 80 characters')
      .test('is-required', 'This is a required field', (value) => {
        if (dueDiligenceType === 'Screening') {
          return true;
        }
        if (
          dueDiligenceLevel?.customFieldsConfig?.customField2?.isMandatory &&
          !value
        ) {
          return false;
        }
        return true;
      }),
    customField3: yup
      .string()
      .max(80, 'Maximum 80 characters')
      .test('is-required', 'This is a required field', (value) => {
        if (dueDiligenceType === 'Screening') {
          return true;
        }
        if (
          dueDiligenceLevel?.customFieldsConfig?.customField3?.isMandatory &&
          !value
        ) {
          return false;
        }
        return true;
      }),
    reportCustomField1: yup
      .string()
      .max(80, 'Maximum 80 characters')
      .test('is-required', 'This is a required field', (value) => {
        if (dueDiligenceType === 'Screening') {
          return true;
        }
        if (
          selectedReport?.customFieldsConfig?.customField1?.isMandatory &&
          !value
        ) {
          return false;
        }
        return true;
      }),
    reportCustomField2: yup
      .string()
      .max(80, 'Maximum 80 characters')
      .test('is-required', 'This is a required field', (value) => {
        if (dueDiligenceType === 'Screening') {
          return true;
        }
        if (
          selectedReport?.customFieldsConfig?.customField2?.isMandatory &&
          !value
        ) {
          return false;
        }
        return true;
      }),
    reportCustomField3: yup
      .string()
      .max(80, 'Maximum 80 characters')
      .test('is-required', 'This is a required field', (value) => {
        if (dueDiligenceType === 'Screening') {
          return true;
        }
        if (
          selectedReport?.customFieldsConfig?.customField3?.isMandatory &&
          !value
        ) {
          return false;
        }
        return true;
      }),
  });

  const dueDiligence = ddTypesData.find((x) => x.name === 'Due Diligence')
    ?.dueDiligenceTypes[0];

  const dueDiligenceLevel = dueDiligence?.managedServiceDueDiligenceConfigs;

  const draftRelatedEntityIds = useMemo(() => {
    return draftData?.requestDetails?.relatedEntities;
  }, [draftData]);

  //   //------- ( Draft Documents ) --------//

  //   //------- ( End Draft Documents ) --------//

  const reportTemplate = dueDiligenceLevel?.reportTemplates?.find(
    (r) =>
      r.id.toString() ===
      draftData?.requestDetails?.dueDiligenceTemplateId.toString()
  );

  const hasReportTemplate1 =
    reportTemplate?.customFieldsConfig?.customField1?.isEnabled &&
    draftData?.requestDetails?.reportCustomField1;
  const hasReportTemplate2 =
    reportTemplate?.customFieldsConfig?.customField2?.isEnabled &&
    draftData?.requestDetails?.reportCustomField2;
  const hasReportTemplate3 =
    reportTemplate?.customFieldsConfig?.customField3?.isEnabled &&
    draftData?.requestDetails?.reportCustomField3;
  const canExpedite =
    reportTemplate?.canExpedite && draftData?.requestDetails?.expedite;

  const formMethods = useForm<InititateDueDiligenceFormFields>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      relatedEntities: [],
      documentsData: [],
      dueDiligenceType: ddTypesData.length === 1 ? 'Screening' : 'DEFAULT',
      screeningType: 'DEFAULT',
      screeningSettings: 'DEFAULT',
      monitoring: 'DEFAULT',
      monitoringSettings: 'DEFAULT',
      monitoringScreeningSettings: 'DEFAULT',
      comments: '',
      customField1: '',
      customField2: '',
      customField3: '',
      reportCustomField1: '',
      reportCustomField2: '',
      reportCustomField3: '',
      reportTemplateId: null,
      acknowledge: false,
      addOns: [],
      expedite: false,
      hasAcknowledgedMessage: false,
      isGlobalSearch: false,
    },
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    trigger,
    watch,
    reset,
    formState: { errors: formValidationErrors, isValid },
  } = formMethods;

  const relatedEntities = useWatch({ control, name: 'relatedEntities' });
  const [formattedRelatedEntities, setFormattedRelatedEntities] = useState<
    SelectedRelatedEntity[]
  >([]);

  const documentsData = useWatch({ control, name: 'documentsData' });

  const dueDiligenceType = useWatch({
    control,
    name: 'dueDiligenceType',
  });
  const screeningType = useWatch({
    control,
    name: 'screeningType',
  });
  const reportTemplateId = useWatch({
    control,
    name: 'reportTemplateId',
  });

  const acknowledge = useWatch({
    control,
    name: 'acknowledge',
  });

  const hasAcknowledgedMessage = useWatch({
    control,
    name: 'hasAcknowledgedMessage',
  });

  const monitoringValue = useWatch({ control, name: 'monitoring' });

  const isManagedDueDiligence = dueDiligenceType === 'Due Diligence';
  const isScreeningDueDiligence = dueDiligenceType === 'Screening';

  //#endregion use form

  //#region internal states

  const [isOpenSection1, setIsOpenSection1] = useState(true);
  const [isOpenSection2, setIsOpenSection2] = useState(false);
  const [isOpenSection3, setIsOpenSection3] = useState(false);
  const [isOpenSection4, setIsOpenSection4] = useState(false);
  const [completedSections, setCompletedSections] = useState([]);
  const [potentialDuplicateModalState, setPotentialDuplicateModalState] =
    useState<PotentialDuplicateModalTypes & { loading: boolean }>({
      isOpen: false,
      loading: false,
      clientScreenings: [],
      companyScreenings: [],
      canViewReport: true,
    });

  //#endregion internal states

  const initializeDraftStep = (currentStep) => {
    let completedSectionsArray = [];

    for (let i = 1; i < currentStep; i++) {
      completedSectionsArray.push(i);
    }

    setCompletedSections(completedSectionsArray);

    setIsOpenSection1(currentStep === 1);
    setIsOpenSection2(currentStep === 2);
    setIsOpenSection3(currentStep === 3);
    setIsOpenSection4(currentStep === 4);
  };

  //#region computed variables

  const urlSearchParams = new URLSearchParams(search);
  const draftIdParam = urlSearchParams ? urlSearchParams.get('draftId') : null;

  const screeningLevel = ddTypesData.find(
    (x) => x.name === 'Screening'
  )?.dueDiligenceTypes;

  const isSelfServiceMonitoringEnabledByDefault =
    screeningLevel &&
    screeningLevel.find(({ name }) => name === 'Self Service')
      ?.isMonitoringEnabledByDefaultOnNewCases;

  const isSelfServiceMonitoringEnabled =
    screeningLevel &&
    screeningLevel.find(({ name }) => name === 'Self Service')
      ?.isMonitoringEnabled;

  const isManagedServiceMonitoringEnabledByDefault =
    screeningLevel &&
    screeningLevel.find(({ name }) => name === 'Managed Service')
      ?.isMonitoringEnabledByDefaultOnNewCases;

  const isManagedServiceMonitoringEnabled =
    screeningLevel &&
    screeningLevel.find(({ name }) => name === 'Managed Service')
      ?.isMonitoringEnabled;

  const initialScreeningSettings =
    screeningLevel && Array.isArray(screeningLevel)
      ? screeningLevel
          .find(({ name }) => name === screeningType)
          ?.screeningSearchTypes?.map(({ id, description }) => ({
            name: description,
            value: id,
          }))
      : [];

  const isGlobalSearchEnabled = useMemo(() => {
    let hasGlobalSearchEnabled;
    if (screeningType === 'Self Service') {
      hasGlobalSearchEnabled = screeningLevel?.find(
        (x) => x.name === 'Self Service'
      )?.globalSearchEnabled;
    } else {
      hasGlobalSearchEnabled = screeningLevel?.find(
        (x) => x.name === 'Managed Service'
      )?.globalSearchEnabled;
    }

    return hasGlobalSearchEnabled;
  }, [screeningLevel, screeningType]);

  const monitoringScreeningSettings = useMemo(() => {
    return screeningLevel && Array.isArray(screeningLevel)
      ? screeningLevel
          .find(({ name }) => name === screeningType)
          ?.ongoingScreeningSearchTypes?.map(({ id, description }) => ({
            name: description,
            value: id,
          }))
      : [];
  }, [screeningType, screeningLevel]);

  const dueDiligenceTypes = useMemo(() => {
    if (screeningLevel) {
      return screeningLevel?.reduce(
        (acc, value) => {
          if (value.name === 'Self Service') {
            return {
              ...acc,
              selfService: true,
            };
          }
          if (value.name === 'Managed Service') {
            return {
              ...acc,
              managedService: true,
            };
          }
          return acc;
        },
        { selfService: false, managedService: false }
      );
    }
    return null;
  }, [screeningLevel]);

  const selectedReport: ReportTemplate | null = useMemo(() => {
    if (dueDiligenceLevel?.reportTemplates && reportTemplateId) {
      const template = dueDiligenceLevel.reportTemplates.find(
        (r) => r.id.toString() === reportTemplateId.toString()
      );

      if (template.documentConfig) {
        template.documentConfig = normalizeVerificationDateConfigs(
          template.documentConfig
        );
      }

      return template;
    }

    return null;
  }, [dueDiligenceLevel, reportTemplateId]);

  const permittedIndividualRelatedEntities =
    thirdPartyType === ThirdPartyType.Individual
      ? selectedReport?.maxIndividualRelatedEntitiesForIndividualThirdParty
      : selectedReport?.maxIndividualRelatedEntitiesForBusinessThirdParty;

  const permittedCompanyRelatedEntities =
    thirdPartyType === ThirdPartyType.Individual
      ? selectedReport?.maxBusinessRelatedEntitiesForIndividualThirdParty
      : selectedReport?.maxBusinessRelatedEntitiesForBusinessThirdParty;

  const hasPermittedRelatedEntities: number =
    permittedIndividualRelatedEntities + permittedCompanyRelatedEntities;

  const selectedRelatedEntities: SelectedRelatedEntity[] = useMemo(() => {
    const relatedEntitiesState = getValues('relatedEntities');
    try {
      return (
        formattedRelatedEntities?.filter((re) => {
          return relatedEntitiesState.includes(re.id);
        }) ?? []
      );
    } catch (error) {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedEntities, formattedRelatedEntities]);

  const isAcknowledgeVisible = (): boolean =>
    hasPermittedRelatedEntities > 0 &&
    (selectedRelatedEntities.filter(
      (re) => re.type == ThirdPartyType.Individual
    ).length > permittedIndividualRelatedEntities ||
      selectedRelatedEntities.filter((re) => re.type == ThirdPartyType.Business)
        .length > permittedCompanyRelatedEntities);

  const isAcknowledgeMessageVisible = useMemo(() => {
    return selectedReport && selectedReport?.includeAcknowledgementMessage;
  }, [selectedReport]);

  const permittedDocumentsUploadAndPermissions = useMemo(() => {
    if (!hasPermission(GatewayPermissions.UploadDocuments)) {
      return false;
    }
    if (!selectedReport?.documentConfig) {
      return false;
    }
    let shouldRender = false;
    if (selectedReport?.documentConfig?.isRequiredDocumentsForThirdParty) {
      const tpDocs = selectedReport?.documentConfig?.thirdPartyDocuments;
      if (
        tpDocs?.thirdPartyConfiguredDocumentsIndividual.length > 0 &&
        thirdPartyType == ThirdPartyType.Individual
      ) {
        shouldRender = true;
      }
      if (
        tpDocs?.thirdPartyConfiguredDocumentsBusiness.length > 0 &&
        thirdPartyType == ThirdPartyType.Business
      ) {
        shouldRender = true;
      }
    }
    if (selectedReport?.documentConfig?.isRequiredDocumentsForRelatedEntities) {
      const reDocs = selectedReport?.documentConfig?.relatedEntitiesDocuments;
      if (
        reDocs?.relatedEntitiesConfiguredDocumentsIndividual.length > 0 &&
        selectedRelatedEntities.find(
          (re) => re.type == ThirdPartyType.Individual
        )
      ) {
        shouldRender = true;
      }
      if (
        reDocs?.relatedEntitiesConfiguredDocumentsBusiness.length > 0 &&
        selectedRelatedEntities.find((re) => re.type == ThirdPartyType.Business)
      ) {
        shouldRender = true;
      }
    }
    if (selectedReport?.documentConfig?.isOptionalDocumentsForThirdParty) {
      shouldRender = true;
    }
    return shouldRender;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport, selectedRelatedEntities, thirdPartyType]);

  //#endregion computed variables

  //#region validations

  const ddTypeIsVisible = (level) => {
    switch (level) {
      case 'Screening':
        return (
          hasPermission(
            GatewayPermissions.InitiateDueDiligenceSelfServiceScreening
          ) ||
          hasPermission(
            GatewayPermissions.InitiateDueDiligenceManagedServiceScreening
          )
        );
      case 'Due Diligence':
        return hasPermission(GatewayPermissions.OrderManagedDueDiligence);
      case 'UBO':
        return hasPermission(
          GatewayPermissions.OrderOwnershipManagedDueDiligence
        );
      default:
        return true;
    }
  };

  const fromEntitiesRetrievedFilesToDocumentsData = (
    entitiesRetrievedFiles: EntitiesRetrievedFile[]
  ) => {
    return entitiesRetrievedFiles.flatMap((entitiesFilesGroup) =>
      entitiesFilesGroup.filesInformation.map((file) => {
        return fileInformationResponseToDocumentData(
          file,
          entitiesFilesGroup.isRelatedEntity,
          entitiesFilesGroup.id,
          file.isOptionalDocument
        );
      })
    );
  };

  useEffect(() => {
    if (!draftData) {
      return;
    }
    reset({
      relatedEntities: draftRelatedEntityIds,
      documentsData: fromEntitiesRetrievedFilesToDocumentsData(
        draftData.entitiesRetrievedFiles
      ),
      dueDiligenceType:
        draftData?.requestDetails?.dueDiligenceTypeId === 5
          ? 'Due Diligence'
          : 'DEFAULT',
      screeningType: 'DEFAULT',
      screeningSettings: 'DEFAULT',
      monitoring: 'DEFAULT',
      monitoringSettings: 'DEFAULT',
      monitoringScreeningSettings: 'DEFAULT',
      comments: draftData?.requestDetails?.comments,
      customField1:
        dueDiligenceLevel?.customFieldsConfig?.customField1?.isEnabled &&
        draftData?.requestDetails?.customField1
          ? draftData?.requestDetails?.customField1
          : '',
      customField2:
        dueDiligenceLevel?.customFieldsConfig?.customField2?.isEnabled &&
        draftData?.requestDetails?.customField2
          ? draftData?.requestDetails?.customField2
          : '',
      customField3:
        dueDiligenceLevel?.customFieldsConfig?.customField3?.isEnabled &&
        draftData?.requestDetails?.customField3
          ? draftData?.requestDetails?.customField3
          : '',
      reportCustomField1: hasReportTemplate1
        ? draftData?.requestDetails?.reportCustomField1
        : '',
      reportCustomField2: hasReportTemplate2
        ? draftData?.requestDetails?.reportCustomField2
        : '',
      reportCustomField3: hasReportTemplate3
        ? draftData?.requestDetails?.reportCustomField3
        : '',
      reportTemplateId: draftData?.requestDetails?.dueDiligenceTemplateId,
      acknowledge: false,
      addOns:
        reportTemplate?.optionalAddOns?.length > 0 &&
        draftData?.requestDetails?.addOns?.length > 0
          ? draftData?.requestDetails?.addOns
          : [],
      expedite: canExpedite ? draftData?.requestDetails?.expedite : false,
      hasAcknowledgedMessage: false,
      isGlobalSearch: false,
    });
    initializeDraftStep(draftData.currentStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftData]);

  useEffect(() => {
    setValue('hasAcknowledgedMessage', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  useEffect(() => {
    let queryParams: Record<string, string | boolean> = { onlyNew: true };

    if (permittedCompanyRelatedEntities === 0) {
      queryParams.type = 'Individual';
    } else if (permittedIndividualRelatedEntities === 0) {
      queryParams.type = 'Business';
    }

    fetchRelatedEntitiesLazy({
      queryParams,
    });
  }, [
    fetchRelatedEntitiesLazy,
    id,
    permittedCompanyRelatedEntities,
    permittedIndividualRelatedEntities,
  ]);

  useEffect(() => {
    setValue('screeningSettings', 'DEFAULT');

    if (screeningType === 'Self Service') {
      if (!isSelfServiceMonitoringEnabled) {
        setValue('monitoring', 'disabled');
      }
      if (isSelfServiceMonitoringEnabledByDefault) {
        setValue('monitoring', 'enabled');
      }
    } else if (screeningType === 'Managed Service') {
      if (!isManagedServiceMonitoringEnabled) {
        setValue('monitoring', 'disabled');
      }
      if (isManagedServiceMonitoringEnabledByDefault) {
        setValue('monitoring', 'enabled');
      }
    } else {
      setValue('monitoring', 'DEFAULT');
    }

    setValue('monitoringScreeningSettings', 'DEFAULT');
    clearErrors('screeningSettings');
  }, [
    screeningType,
    setValue,
    clearErrors,
    isSelfServiceMonitoringEnabledByDefault,
    isManagedServiceMonitoringEnabledByDefault,
    isSelfServiceMonitoringEnabled,
    isManagedServiceMonitoringEnabled,
  ]);

  const checkDuplicatedMDDScreeningFetch = async (
    thirdPartyId: number,
    relatedEntityIds: number[],
    templateId: number
  ) => {
    try {
      const url = new URL(`${vantageWebApi}/onlinescreening/duplicates`);

      url.searchParams.append('thirdPartyId', thirdPartyId.toString());
      relatedEntityIds.forEach((relatedEntityId) => {
        url.searchParams.append(
          'relatedEntitiesIds',
          relatedEntityId.toString()
        );
      });
      url.searchParams.append('templateId', templateId.toString());

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const jsonResponse = await response.json();

      return jsonResponse as CheckDuplicateResponse;
    } catch (error) {
      console.error('Error: fetch to /onlinescreening/duplicates', error);
    }
  };

  const evaluateIfShouldOpenDuplicatedMDDModal = async () => {
    try {
      setPotentialDuplicateModalState((prev) => ({
        ...prev,
        loading: true,
      }));
      const checkDuplicatedMDDScreening =
        await checkDuplicatedMDDScreeningFetch(
          id,
          selectedRelatedEntities.map((re) => re.id),
          selectedReport?.id
        );

      const { byClient, byCompany } = checkDuplicatedMDDScreening;

      const clientScreenings: DuplicatedScreening[] = byClient.map(
        (screening) => ({
          id: screening.screeningId,
          createdDate: screening.requestDate,
          reportStatus: screening.reportStatus,
          userFullName: screening.requesterName,
        })
      );

      const companyScreenings: DuplicatedScreening[] = byCompany.map(
        (screening) => ({
          id: screening.screeningId,
          createdDate: screening.requestDate,
          reportStatus: screening.reportStatus,
          userFullName: screening.requesterName,
        })
      );

      setPotentialDuplicateModalState((prev) => ({
        ...prev,
        loading: false,
      }));

      if (clientScreenings.length > 0 || companyScreenings.length > 0) {
        setPotentialDuplicateModalState({
          isOpen: true,
          loading: false,
          canViewReport: hasPermission(GatewayPermissions.Admin),
          clientScreenings,
          companyScreenings,
        });
      }
    } catch (error) {
      console.error('Error: Open Potential Duplicates Modal', error);
    } finally {
      setPotentialDuplicateModalState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const stepOneEnableNextSections = () => {
    setIsOpenSection1(false);
    const temp = [...completedSections];
    if (isScreeningDueDiligence || isManagedDueDiligence) {
      let index = temp.indexOf(1);
      if (hasPermittedRelatedEntities === 0) {
        if (!permittedDocumentsUploadAndPermissions) {
          setIsOpenSection4(true);
          if (index === -1) {
            setCompletedSections([1, 2, 3, ...temp]);
            return;
          }
        } else {
          setIsOpenSection3(true);
          if (index === -1) {
            setCompletedSections([1, 2, ...temp]);
            return;
          }
        }
      } else {
        setIsOpenSection2(true);
        if (index === -1) {
          setCompletedSections([1, ...temp]);
          return;
        }
      }
    }
  };

  const stepTwoEnableNextSections = () => {
    const temp = [...completedSections];
    if (!permittedDocumentsUploadAndPermissions) {
      setIsOpenSection2(false);
      setIsOpenSection3(false);
      setIsOpenSection4(true);
      let index = temp.indexOf(2);
      if (index === -1) {
        setCompletedSections([2, 3, ...completedSections]);
      }
    } else {
      setIsOpenSection2(false);
      setIsOpenSection3(true);
      let index = temp.indexOf(2);
      if (index === -1) {
        setCompletedSections([2, ...completedSections]);
      }
    }
  };

  const stepThreeEnableNextSections = () => {
    const temp = [...completedSections];
    setIsOpenSection3(false);
    setIsOpenSection4(true);
    let index = temp.indexOf(3);
    if (index === -1) {
      setCompletedSections([3, ...completedSections]);
    }
  };

  const handleComplianceCheckTypeNextSectionClick = async () => {
    if (hasPermittedRelatedEntities === 0) {
      await evaluateIfShouldOpenDuplicatedMDDModal();
    }
    stepOneEnableNextSections();
  };

  const handleRelatedEntitiesNextSectionClick = async () => {
    await evaluateIfShouldOpenDuplicatedMDDModal();
    stepTwoEnableNextSections();
  };

  const handleThirdPartyDocumentsNextSectionClick = async () => {
    if (!hasPermittedRelatedEntities) {
      await checkDuplicatedMDDScreeningFetch(
        id,
        selectedRelatedEntities.map((re) => re.id),
        selectedReport?.id
      );
    }

    await trigger('documentsData');
    if ((formValidationErrors.documentsData as any)?.message) {
      return;
    }

    stepThreeEnableNextSections();
  };

  const isDisabledRelatedEntitesNextSection = useMemo(() => {
    if (!acknowledge && isAcknowledgeVisible()) {
      return true;
    }
    if (potentialDuplicateModalState.loading) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    acknowledge,
    isAcknowledgeVisible,
    hasPermittedRelatedEntities,
    selectedRelatedEntities,
    permittedCompanyRelatedEntities,
    potentialDuplicateModalState.loading,
  ]);

  useEffect(() => {
    if (
      isSelfServiceMonitoringEnabledByDefault &&
      screeningType === 'Self Service'
    ) {
      setValue('monitoring', 'enabled');
    }

    if (
      isManagedServiceMonitoringEnabledByDefault &&
      screeningType === 'Managed Service'
    ) {
      setValue('monitoring', 'enabled');
    }

    if (
      dueDiligenceTypes?.selfService &&
      !isSelfServiceMonitoringEnabled &&
      !isSelfServiceMonitoringEnabledByDefault &&
      screeningType === 'Self Service'
    ) {
      setValue('monitoring', 'disabled');
    }

    if (
      dueDiligenceTypes?.managedService &&
      !isManagedServiceMonitoringEnabled &&
      !isManagedServiceMonitoringEnabledByDefault &&
      screeningType === 'Managed Service'
    ) {
      setValue('monitoring', 'disabled');
    }

    if (dueDiligenceType === 'Screening') {
      if (
        dueDiligenceTypes?.selfService &&
        !dueDiligenceTypes?.managedService
      ) {
        setValue('screeningType', 'Self Service');
      }
      if (
        !dueDiligenceTypes?.selfService &&
        dueDiligenceTypes?.managedService
      ) {
        setValue('screeningType', 'Managed Service');
      }
    }

    if (
      dueDiligenceType === 'Due Diligence' &&
      dueDiligenceLevel?.reportTemplates?.length === 1
    ) {
      setValue('reportTemplateId', dueDiligenceLevel.reportTemplates[0].id);
    }
  }, [
    screeningType,
    isSelfServiceMonitoringEnabled,
    isSelfServiceMonitoringEnabledByDefault,
    isManagedServiceMonitoringEnabled,
    isManagedServiceMonitoringEnabledByDefault,
    setValue,
    dueDiligenceTypes?.selfService,
    dueDiligenceTypes?.managedService,
    dueDiligenceType,
    dueDiligenceLevel?.reportTemplates,
  ]);

  useEffect(() => {
    if (
      monitoringScreeningSettings &&
      monitoringScreeningSettings.length === 1 &&
      (screeningType === 'Self Service' || screeningType === 'Managed Service')
    ) {
      setValue(
        'monitoringScreeningSettings',
        monitoringScreeningSettings[0].value.toString()
      );
      return;
    }

    setValue('monitoringScreeningSettings', 'DEFAULT');
  }, [setValue, monitoringValue, monitoringScreeningSettings, screeningType]);

  useEffect(() => {
    if (
      acknowledge &&
      selectedRelatedEntities?.length > hasPermittedRelatedEntities
    ) {
      setValue('acknowledge', false);
    }
    // ignoring getPermittedRelatedEntities, to avoid warning.
    // eslint-disable-next-line
  }, [setValue, selectedRelatedEntities?.length]);

  //#endregion validations

  //#region handlers

  const normalizeDocumentsDataForMDDRequest = (
    documentsData: DocumentData[]
  ): DocumentDataForMDDRequest[] => {
    return documentsData.reduce((normalized, currentDocumentData) => {
      const found = normalized.find(
        (doc) =>
          doc.id === currentDocumentData.entityId &&
          doc.isRelatedEntity === currentDocumentData.isRelatedEntityDocument
      );
      if (found) {
        if (currentDocumentData.isOptionalDocument) {
          found.optionalDocuments.push(currentDocumentData.id);
        } else {
          found.documentsIds.push(currentDocumentData.id);
        }

        return normalized;
      } else {
        const newRecord = {
          id: currentDocumentData.entityId,
          isRelatedEntity: currentDocumentData.isRelatedEntityDocument,
          documentsIds: [],
          optionalDocuments: [],
        };

        if (currentDocumentData.isOptionalDocument) {
          newRecord.optionalDocuments = [currentDocumentData.id];
        } else {
          newRecord.documentsIds = [currentDocumentData.id];
        }

        return [...normalized, newRecord];
      }
    }, [] as DocumentDataForMDDRequest[]);
  };

  const findCurrentStep = () => {
    const isOpenSections = [
      isOpenSection1,
      isOpenSection2,
      isOpenSection3,
      isOpenSection4,
    ];
    const openIndex = isOpenSections.reverse().findIndex((isOpen) => isOpen);
    return openIndex === -1 ? 1 : isOpenSections.length - openIndex;
  };

  const sectionDueDiligenceConfirmationModal = (
    formData: InititateDueDiligenceFormFields,
    selectedReport: any,
    selectedRelatedEntities: any[],
    dueDiligenceLevelState: any,
    optionalAddonsData: any
  ): Section => {
    const blocks = [];

    if (selectedReport?.fee) {
      const fee = selectedReport?.fee.toFixed();
      const maxFee = selectedReport?.maxFee
        ? selectedReport?.maxFee.toFixed()
        : '';
      const currency = getSymbolFromCurrency(selectedReport?.feeCurrencyCode);
      const feeResult =
        selectedReport.isFeeRange && selectedReport.maxFee
          ? `${currency}${fee} - ${currency}${maxFee}`
          : `${currency}${fee}`;
      blocks.push({
        title: 'Fee',
        lines: [feeResult],
      });
    }

    if (selectedReport?.managedServiceDueDiligenceSLA) {
      blocks.push({
        title: 'Standard delivery timeframe',
        lines: [
          `Up to ${selectedReport?.managedServiceDueDiligenceSLA} working days`,
        ],
      });
    }

    const expediteFee = `${
      selectedReport?.expediteFee
        ? ' - ' +
          getSymbolFromCurrency(selectedReport?.expediteFeeCurrencyCode) +
          selectedReport?.expediteFee.toFixed()
        : ''
    }`;

    blocks.push({
      title: 'Expedited?',
      lines: [`${formData.expedite ? `Yes${expediteFee}` : 'No'}`],
    });

    if (selectedRelatedEntities.length > 0) {
      blocks.push({
        title: 'Related Entites',
        lines: selectedRelatedEntities.map(
          (relatedEntity) => relatedEntity?.name
        ),
      });
    }

    if (formData?.comments) {
      blocks.push({
        title: 'Other information',
        lines: [formData?.comments],
      });
    }

    if (
      dueDiligenceLevelState?.customFieldsConfig?.customField1?.isEnabled &&
      formData?.customField1
    ) {
      blocks.push({
        title: dueDiligenceLevelState.customFieldsConfig?.customField1.label,
        lines: [formData?.customField1],
      });
    }

    if (
      dueDiligenceLevelState?.customFieldsConfig?.customField2?.isEnabled &&
      formData?.customField2
    ) {
      blocks.push({
        title: dueDiligenceLevelState.customFieldsConfig?.customField2.label,
        lines: [formData?.customField2],
      });
    }

    if (
      dueDiligenceLevelState?.customFieldsConfig?.customField3?.isEnabled &&
      formData?.customField3
    ) {
      blocks.push({
        title: dueDiligenceLevelState.customFieldsConfig?.customField3.label,
        lines: [formData?.customField3],
      });
    }

    if (
      selectedReport?.customFieldsConfig?.customField1?.isEnabled &&
      formData?.reportCustomField1
    ) {
      blocks.push({
        title: selectedReport.customFieldsConfig.customField1.label,
        lines: [formData?.reportCustomField1],
      });
    }

    if (
      selectedReport?.customFieldsConfig?.customField2?.isEnabled &&
      formData?.reportCustomField2
    ) {
      blocks.push({
        title: selectedReport.customFieldsConfig.customField2.label,
        lines: [formData?.reportCustomField2],
      });
    }

    if (
      selectedReport?.customFieldsConfig?.customField3?.isEnabled &&
      formData?.reportCustomField3
    ) {
      blocks.push({
        title: selectedReport.customFieldsConfig.customField3.label,
        lines: [formData?.reportCustomField3],
      });
    }

    if (formData?.addOns?.filter((ao) => ao).length > 0) {
      blocks.push({
        title: 'Add-Ons (and fees):',
        lines: [
          formData.addOns
            .map((addon) => {
              if (addon) {
                const selectedAddon = selectedReport?.optionalAddOns.find(
                  (oa) => oa.optionalAddOnId === Number(addon)
                );
                const addonObject = optionalAddonsData?.find(
                  (obj) => obj.id === selectedAddon.optionalAddOnId
                );
                const addonFee = selectedAddon.fee
                  ? ` - ${getSymbolFromCurrency(
                      selectedAddon.feeCurrencyCode
                    )}${selectedAddon.fee.toFixed()}`
                  : '';
                return addonObject
                  ? `${addonObject?.description}${addonFee}`
                  : '';
              } else {
                return '';
              }
            })
            .filter((oa) => oa),
        ],
      });
    }

    return {
      title: 'Additional Information',
      blocks,
    };
  };

  const getDueDiligenceData = () => {
    const normalizedDocs = normalizeDocumentsDataForMDDRequest(documentsData);
    return {
      currentStep: findCurrentStep(),
      dueDiligenceTypeId: dueDiligence?.id,
      dueDiligenceTemplateId: reportTemplateId,
      relatedEntities: getValues('relatedEntities'),
      comments: getValues('comments'),
      addOns: getValues('addOns')
        .filter((id) => id !== false)
        .map((id) => parseInt(id)),
      customField1: getValues('customField1'),
      customField2: getValues('customField2'),
      customField3: getValues('customField3'),
      reportCustomField1: getValues('reportCustomField1'),
      reportCustomField2: getValues('reportCustomField2'),
      reportCustomField3: getValues('reportCustomField3'),
      expedite: getValues('expedite'),
      documentsData: normalizedDocs,
      draftId: draftIdParam,
      summaryDescriptions: {
        selectedReport,
        selectedRelatedEntities,
        dueDiligenceType,
        dueDiligenceLevel,
        hasPermittedRelatedEntities,
        screeningType,
      },

      thirdPartyInfo: {
        thirdPartyLabel: terminologyConfig?.thirdPartySingularNaming,
        name: thirdPartyName,
        internalId: thirdPartyInternalId,
        dueDiligenceType: dueDiligenceType,
        level: selectedReport?.reportName,
        attachments: normalizedDocs?.flatMap((entity) => [
          ...(entity.documentsIds || []),
          ...(entity.optionalDocuments || []),
        ])?.length,
      } as ThirdPartyInfo,
      section: sectionDueDiligenceConfirmationModal(
        getValues(),
        selectedReport,
        selectedRelatedEntities,
        dueDiligenceLevel,
        optionalAddonsData
      ) as Section,
    };
  };

  const getScreeningData = () => {
    return {
      screeningSearchTypeId: Number(getValues('screeningSettings')),
      isGlobalSearch: isGlobalSearchEnabled
        ? getValues('isGlobalSearch')
        : null,
      isMonitoringEnabled:
        screeningType === 'Self Service' || 'Managed Service'
          ? getValues('monitoring') === 'enabled'
          : false,
      ongoingScreeningSearchTypeId:
        getValues('monitoringScreeningSettings') === 'DEFAULT' ||
        getValues('monitoring') === 'disabled'
          ? null
          : Number(getValues('monitoringScreeningSettings')),
      summaryDescriptions: {
        selectedReport,
        selectedRelatedEntities,
        dueDiligenceType,
        dueDiligenceLevel,
        hasPermittedRelatedEntities,
        screeningType,
      },
    };
  };

  const formatEntity = (entity: Record<string, any>) =>
    ({
      gender: entity.gender || '',
      jobTitle: entity.linkType || '',
      primaryCountry: entity.primaryCountry,
      ...entity,
    } as SelectedRelatedEntity);

  useEffect(() => {
    if (relatedEntitiesRawData && Array.isArray(relatedEntitiesRawData)) {
      const formattedRelatedEntities = relatedEntitiesRawData.map((entity) =>
        formatEntity(entity)
      );
      setFormattedRelatedEntities(formattedRelatedEntities);
    }
  }, [relatedEntitiesRawData]);

  const prependRelatedEntity = (entity: Record<string, any>) => {
    setFormattedRelatedEntities((prev) => [...prev, formatEntity(entity)]);
    setValue('relatedEntities', [entity.id, ...getValues('relatedEntities')]);
  };

  const updateRelatedEntity = (entity: Record<string, any>) => {
    const updatedEntity = formatEntity(entity);

    setFormattedRelatedEntities((prevEntities) => {
      const entityIndex = prevEntities.findIndex(
        (item) => item.id === updatedEntity.id
      );
      const updatedEntities = [...prevEntities];
      updatedEntities[entityIndex] = updatedEntity;
      return updatedEntities;
    });

    setValue('relatedEntities', [
      updatedEntity.id,
      ...getValues('relatedEntities'),
    ]);
  };

  const handleScreeningSubmit = async () => {
    await handleSubmit(() => {
      onExternalScreeningSubmit(getScreeningData());
    })();
  };

  const handleDueDiligenceSubmit = async () => {
    trigger();
    setCompletedSections([3, ...completedSections]);
    await handleSubmit(() => {
      onExternalDueDiligenceSubmit(getDueDiligenceData());
    })();
  };

  const handleSaveDraft = () => {
    onSaveDraft(getDueDiligenceData());
  };
  //#endregion handlers

  //#endregion dependencies
  return (
    <AppLayout
      topBarSlot={
        <StyledLink to={`/third-party/profile/${id}`} dark>
          {`Back to ${terminologyConfig?.thirdPartySingularNaming} manager`}
        </StyledLink>
      }
    >
      <PageTitle
        title={`${terminologyConfig?.thirdPartySingularNaming}: ${thirdPartyName}`}
      />
      <FormProvider {...formMethods}>
        <DueDiligence>
          <Wrapper>
            <FormHeader>Initiate compliance check</FormHeader>
            {loading ? (
              <>
                <Loading />
                <LoadingMessage>
                  Please wait. Your order is being processed.
                </LoadingMessage>
              </>
            ) : (
              <DueDiligenceForm>
                <Accordion
                  title="Compliance check type"
                  isOpen={isOpenSection1}
                  setIsOpen={() => {}}
                  onClick={(event) => {
                    event.preventDefault();
                    setIsOpenSection1(!isOpenSection1);
                  }}
                  completed={completedSections.includes(1)}
                >
                  <DueDiligenceType>
                    <ResponsiveContainer className="radio-group">
                      {ddTypesData.map(
                        (level, index) =>
                          ddTypeIsVisible(level.name) && (
                            <FieldRadio
                              key={index}
                              label={level.name}
                              id={level.name}
                              value={level.name}
                              name={`dueDiligenceType`}
                              register={register}
                            >
                              <Abbreviation
                                abbreviation={level.name.replace(' ', '')}
                              />
                              <span>{level.name}</span>
                            </FieldRadio>
                          )
                      )}
                    </ResponsiveContainer>
                  </DueDiligenceType>
                  {isScreeningDueDiligence && (
                    <FormControl helperText="*Mandatory Fields">
                      <label>Level</label>
                      <ResponsiveContainer className="radio-group">
                        {dueDiligenceTypes?.selfService &&
                          hasPermission(
                            GatewayPermissions.InitiateDueDiligenceSelfServiceScreening
                          ) && (
                            <FieldRadio
                              label="Self Service Screening"
                              id="selfServiceScreening"
                              value="Self Service"
                              name="screeningType"
                              register={register}
                              checked={!dueDiligenceTypes?.managedService}
                            >
                              <span>Self Service Screening</span>
                            </FieldRadio>
                          )}
                        {dueDiligenceTypes?.managedService &&
                          hasPermission(
                            GatewayPermissions.InitiateDueDiligenceManagedServiceScreening
                          ) && (
                            <FieldRadio
                              label="Managed Service Screening"
                              id="managedServiceScreening"
                              value="Managed Service"
                              name="screeningType"
                              register={register}
                            >
                              <span>Managed Service Screening</span>
                            </FieldRadio>
                          )}
                      </ResponsiveContainer>
                    </FormControl>
                  )}
                  {isManagedDueDiligence && (
                    <FormControl helperText="*Mandatory Fields">
                      <label>Level</label>
                      <ResponsiveContainer className="radio-group">
                        {dueDiligenceLevel &&
                          dueDiligenceLevel.reportTemplates.map((t) => (
                            <FieldRadio
                              key={t.id}
                              label={t.reportName}
                              id={t.id.toString()}
                              value={t.id.toString()}
                              name="reportTemplateId"
                              register={register}
                            >
                              <div>
                                <p>{t.reportName}</p>
                                <p>
                                  {t.fee
                                    ? getSymbolFromCurrency(t.feeCurrencyCode) +
                                      t.fee.toFixed()
                                    : ''}
                                  {t.isFeeRange && t.maxFee && t.fee
                                    ? ' - ' +
                                      getSymbolFromCurrency(t.feeCurrencyCode) +
                                      t.maxFee.toFixed()
                                    : ''}
                                  {t.fee && t.managedServiceDueDiligenceSLA
                                    ? ' - '
                                    : ''}
                                  {t.managedServiceDueDiligenceSLA
                                    ? `Up to ${t.managedServiceDueDiligenceSLA} working days`
                                    : ''}
                                </p>
                                {!t.managedServiceDueDiligenceSLA && !t.fee && (
                                  <br />
                                )}
                              </div>
                            </FieldRadio>
                          ))}
                      </ResponsiveContainer>
                    </FormControl>
                  )}
                  <div className="buttons">
                    <Button
                      text
                      type="button"
                      onClick={() => history.push(`/third-party/profile/${id}`)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      disabled={
                        (watch('dueDiligenceType') === 'Screening' &&
                          watch('screeningType') === 'DEFAULT') ||
                        (watch('dueDiligenceType') === 'Due Diligence' &&
                          watch('reportTemplateId') === null) ||
                        watch('dueDiligenceType') === 'DEFAULT' ||
                        relatedEntitiesRawDataLoading ||
                        potentialDuplicateModalState.loading
                      }
                      onClick={handleComplianceCheckTypeNextSectionClick}
                      secondary
                    >
                      {relatedEntitiesRawDataLoading ||
                      potentialDuplicateModalState.loading ? (
                        <Loading />
                      ) : (
                        'Next section'
                      )}
                    </Button>
                  </div>
                </Accordion>
                {isScreeningDueDiligence && (
                  <Accordion
                    title="Screening settings"
                    isOpen={isOpenSection2}
                    onClick={(event) => {
                      if (completedSections.includes(1)) {
                        event.preventDefault();
                        setIsOpenSection2(!isOpenSection2);
                      }
                    }}
                    completed={completedSections.includes(2)}
                  >
                    <FormControl helperText="*Mandatory Fields">
                      <div className="screening-settings-container">
                        <>
                          <ResponsiveContainer>
                            <FormControl
                              errorMessage={
                                formValidationErrors?.screeningSettings?.message
                              }
                            >
                              <ComboBoxContainer>
                                <FieldSelect
                                  valid={
                                    formValidationErrors?.screeningSettings ===
                                    undefined
                                  }
                                  fullWidth
                                  ariaLabel="Initial screening settings *"
                                  id="screeningSettings"
                                  label="Initial screening settings *"
                                  options={initialScreeningSettings}
                                  placeholder="select..."
                                  placeholderValue="DEFAULT"
                                  register={register}
                                />
                              </ComboBoxContainer>
                            </FormControl>
                            {isGlobalSearchEnabled && (
                              <GlobalSearch>
                                <FieldCheckbox
                                  ariaLabel="isGlobalSearch"
                                  id="isGlobalSearch"
                                  name="isGlobalSearch"
                                  label="Perform global search"
                                  register={register}
                                />
                              </GlobalSearch>
                            )}
                          </ResponsiveContainer>
                          {(screeningType === 'Self Service' ||
                            screeningType === 'Managed Service') && (
                            <FormControl
                              errorMessage={
                                formValidationErrors?.monitoring?.message
                              }
                            >
                              <ComboBoxContainer>
                                <FieldSelect
                                  valid={
                                    formValidationErrors?.monitoring ===
                                    undefined
                                  }
                                  fullWidth
                                  ariaLabel="Monitoring *"
                                  id="monitoring"
                                  label="Monitoring *"
                                  placeholder="select..."
                                  disabled={
                                    (screeningType === 'Self Service' &&
                                      (isSelfServiceMonitoringEnabledByDefault ||
                                        (!isSelfServiceMonitoringEnabledByDefault &&
                                          !isSelfServiceMonitoringEnabled))) ||
                                    (screeningType === 'Managed Service' &&
                                      (isManagedServiceMonitoringEnabledByDefault ||
                                        (!isManagedServiceMonitoringEnabledByDefault &&
                                          !isManagedServiceMonitoringEnabled)))
                                  }
                                  onChange={() => {}}
                                  placeholderValue="DEFAULT"
                                  options={[
                                    {
                                      name: 'Enabled',
                                      value: 'enabled',
                                    },
                                    {
                                      name: 'Disabled',
                                      value: 'disabled',
                                    },
                                  ]}
                                  register={register}
                                />
                              </ComboBoxContainer>
                            </FormControl>
                          )}
                          {monitoringValue === 'enabled' &&
                            (screeningType === 'Self Service' ||
                              screeningType === 'Managed Service') && (
                              <FormControl
                                errorMessage={
                                  formValidationErrors
                                    ?.monitoringScreeningSettings?.message
                                }
                              >
                                <FieldSelect
                                  valid={
                                    formValidationErrors?.monitoringScreeningSettings ===
                                    undefined
                                  }
                                  fullWidth
                                  ariaLabel="Monitoring screening settings *"
                                  id="monitoringScreeningSettings"
                                  label="Monitoring screening settings *"
                                  onChange={() => {}}
                                  options={monitoringScreeningSettings}
                                  placeholder="select..."
                                  placeholderValue="DEFAULT"
                                  disabled={
                                    monitoringScreeningSettings.length === 1
                                  }
                                  register={register}
                                />
                              </FormControl>
                            )}
                        </>
                        <div className="buttons">
                          <Button
                            text
                            type="button"
                            onClick={() =>
                              history.push(`/third-party/profile/${id}`)
                            }
                          >
                            Cancel
                          </Button>
                          <Button
                            className="submit-button"
                            type="button"
                            secondary
                            onClick={handleScreeningSubmit}
                          >
                            Submit order
                          </Button>
                        </div>
                      </div>
                    </FormControl>
                  </Accordion>
                )}
                {isManagedDueDiligence && (
                  <>
                    {hasPermittedRelatedEntities > 0 && (
                      <Accordion
                        title="Related entities"
                        isOpen={isOpenSection2}
                        onClick={(event) => {
                          if (completedSections.includes(1)) {
                            event.preventDefault();
                            setIsOpenSection2(!isOpenSection2);
                          }
                        }}
                        completed={completedSections.includes(2)}
                      >
                        <RelatedEntitiesForm
                          thirdPartyId={id}
                          numberOfIndividualPermitted={
                            permittedIndividualRelatedEntities
                          }
                          numberOfCompanyPermitted={
                            permittedCompanyRelatedEntities
                          }
                          relatedEntityFieldsConfigs={{
                            isIndividualDateOfBirthMandatory:
                              thirdPartyData.isIndividualDateOfBirthMandatory,
                            isIndividualRelationshipToThirdPartyMandatory:
                              thirdPartyData.isIndividualRelationshipToThirdPartyMandatory,
                            isCompanyRelationshipToThirdPartyMandatory:
                              thirdPartyData.isCompanyRelationshipToThirdPartyMandatory,
                          }}
                          relatedEntitiesData={formattedRelatedEntities}
                          onSuccessCreated={prependRelatedEntity}
                          onSuccessUpdated={updateRelatedEntity}
                        />
                        {isAcknowledgeVisible() && (
                          <Panel>
                            <NoteContainer>
                              Note:
                              <AcknowledgeWrapper>
                                You have chosen more than your contractually
                                agreed number of related entities. This will
                                incur additional fees. A member of Control Risks
                                will contact you to inform you of the total fee
                                of the report before proceeding with the request
                                <FieldRadioContainer>
                                  <FieldCheckbox
                                    ariaLabel="acknowledge"
                                    id="acknowledge"
                                    name="acknowledge"
                                    label="I acknowledge the above and am happy to
  proceed"
                                    register={register}
                                  />
                                </FieldRadioContainer>
                              </AcknowledgeWrapper>
                            </NoteContainer>
                          </Panel>
                        )}
                        <br />
                        <div className="buttons">
                          <Button
                            text
                            type="button"
                            onClick={() =>
                              history.push(`/third-party/profile/${id}`)
                            }
                          >
                            Cancel
                          </Button>
                          <Button
                            secondary
                            outline
                            type="button"
                            onClick={handleSaveDraft}
                          >
                            Save Draft
                          </Button>
                          <Button
                            secondary
                            type="button"
                            disabled={isDisabledRelatedEntitesNextSection}
                            onClick={handleRelatedEntitiesNextSectionClick}
                          >
                            {potentialDuplicateModalState.loading ? (
                              <Loading />
                            ) : (
                              'Next section'
                            )}
                          </Button>
                        </div>
                      </Accordion>
                    )}
                    {permittedDocumentsUploadAndPermissions && (
                      <Accordion
                        title={`${terminologyConfig?.thirdPartySingularNaming} documents`}
                        isOpen={isOpenSection3}
                        onClick={(event) => {
                          if (completedSections.includes(2)) {
                            event.preventDefault();
                            setIsOpenSection3(!isOpenSection3);
                          }
                        }}
                        completed={completedSections.includes(3)}
                      >
                        <Controller
                          control={control}
                          name="documentsData"
                          render={({
                            field: { value, onChange },
                            fieldState: { error, invalid },
                          }) => {
                            return (
                              <MultipleDocumentUploadWrapper
                                thirdPartyId={id}
                                thirdPartyData={thirdPartyData}
                                categories={categories}
                                documentsConfig={selectedReport.documentConfig}
                                selectedRelatedEntities={
                                  selectedRelatedEntities
                                }
                                value={value}
                                onChange={onChange}
                                error={error}
                                isValid={!invalid}
                              />
                            );
                          }}
                        />
                        <div className="buttons">
                          <Button
                            text
                            type="button"
                            onClick={() => {
                              history.push(`/third-party/profile/${id}`);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="next-button"
                            secondary
                            outline
                            type="button"
                            onClick={handleSaveDraft}
                          >
                            Save Draft
                          </Button>
                          <Button
                            className="next-button"
                            type="button"
                            secondary
                            onClick={handleThirdPartyDocumentsNextSectionClick}
                          >
                            Next section
                          </Button>
                        </div>
                      </Accordion>
                    )}
                    <Accordion
                      title="Other information"
                      isOpen={isOpenSection4}
                      onClick={(event) => {
                        if (completedSections.includes(3)) {
                          event.preventDefault();
                          setIsOpenSection4(!isOpenSection4);
                        }
                      }}
                      completed={completedSections.includes(4)}
                    >
                      <div className="screening-settings-container">
                        <OtherInformation
                          register={register}
                          dueDiligenceLevel={dueDiligenceLevel}
                          selectedReport={selectedReport}
                          formValidationErrors={formValidationErrors}
                        />
                        {isAcknowledgeMessageVisible && (
                          <Panel>
                            <NoteContainer>
                              Note:
                              <AcknowledgeWrapper>
                                {selectedReport?.acknowledgementMessage}
                                <FieldRadioContainer>
                                  <FieldCheckbox
                                    ariaLabel="hasAcknowledgedMessage"
                                    id="hasAcknowledgedMessage"
                                    name="hasAcknowledgedMessage"
                                    label="I acknowledge the above and am happy to
                            proceed"
                                    register={register}
                                  />
                                </FieldRadioContainer>
                              </AcknowledgeWrapper>
                            </NoteContainer>
                          </Panel>
                        )}
                        {!isValid && (
                          <p style={{ color: 'red' }}>
                            There are some errors that need your attention
                          </p>
                        )}
                        <div className="buttons">
                          Other information
                          <Button
                            text
                            type="button"
                            onClick={() =>
                              history.push(`/third-party/profile/${id}`)
                            }
                          >
                            Cancel
                          </Button>
                          <Button
                            className="next-button"
                            secondary
                            outline
                            type="button"
                            onClick={handleSaveDraft}
                          >
                            Save Draft
                          </Button>
                          <Button
                            className="submit-button"
                            type="button"
                            secondary
                            onClick={handleDueDiligenceSubmit}
                            disabled={
                              (isManagedDueDiligence &&
                                !acknowledge &&
                                isAcknowledgeVisible()) ||
                              (isManagedDueDiligence &&
                                !hasAcknowledgedMessage &&
                                isAcknowledgeMessageVisible)
                            }
                          >
                            Submit order
                          </Button>
                        </div>
                      </div>
                    </Accordion>
                  </>
                )}
              </DueDiligenceForm>
            )}
          </Wrapper>
          <SideModulesWrapper>
            <ThirdPartyOverviewSideModule
              thirdParty={thirdPartyData}
              thirdPartyType="third party"
            />
            {isManagedDueDiligence &&
              completedSections.includes(2) &&
              hasPermittedRelatedEntities > 0 && (
                <RelatedEntitiesSideModule
                  relatedEntitiesData={formattedRelatedEntities}
                />
              )}
          </SideModulesWrapper>
        </DueDiligence>
      </FormProvider>

      <PotentialDuplicateModal
        {...potentialDuplicateModalState}
        onProceed={() =>
          setPotentialDuplicateModalState((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
        onClose={() =>
          setPotentialDuplicateModalState((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
        onCancel={() => history.push(`/third-party/profile/${id}`)}
      />
    </AppLayout>
  );
};

export default InitiateDueDiligenceForm;
